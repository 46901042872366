* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #F3F3F3;
}

.flexbox {
  display: flex;
  justify-content: space-between;

  width: 100%;
  max-width: 768px;
  height: 100vh;

  overflow: hidden;

  margin: 0 auto;
  padding: 15px;
}

.flexbox .board {
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 300px;

  background-color: #333333;

  padding: 15px
}

.flexbox .card {
  padding: 15px 25px;
  background-color: #F3F3F3;

  cursor: pointer;
  margin-bottom: 15px;
}









